<script lang="ts">
  const languages = [
    { title: "English", level: 10 },
    { title: "Norwegian", level: 10 },
    { title: "Swedish", level: 9 },
    { title: "Danish", level: 8 },
    { title: "Japanese", level: 5 },
    { title: "German", level: 2 },
    { title: "Spanish", level: 2 },
  ];
</script>

<style>
  table {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 16px 32px 32px;

    background-color: hsla(0, 0%, 100%, 0.4);
    box-shadow: 1px 1px 5px 0 hsla(0, 0%, 0%, 0.3);
    border-radius: 4px;

    overflow: hidden;
  }
  tr {
    display: flex;
  }
  tr:first-child {
    background-color: hsla(0, 0%, 0%, 0.8);
    color: hsla(0, 0%, 100%, 1);
  }
  tr:last-child {
    border: none;
  }
  td,
  th {
    flex: 1;
    padding: 8px;
  }
  td:first-child,
  th:first-child {
    min-width: 90px;
    max-width: 90px;
  }
  th {
    font-weight: 300;
    text-align: left;
  }
  td {
    font-weight: 600;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .level {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.8);
    margin: 0 4px 0 0;
  }
  .level.level--filled {
    background-color: hsla(0, 0%, 0%, 0.8);
  }

  @media (min-width: 1350px) and (max-width: 1500px) {
    .level {
      height: 12px;
      width: 12px;
    }
  }
  @media (max-width: 600px) {
    .level {
      height: 8px;
      width: 8px;
    }
  }
</style>

<svelte:options tag="x-languages" />

<table>
  <tr>
    <th>Language</th>
    <th>Proficiency</th>
  </tr>

  {#each languages as { title, level }}
    <tr>
      <td class="language-title">{title}</td>
      <td>
        {#each Array(level) as _isFilled}
          <span class="level level--filled" />
        {/each}
        {#each Array(10 - level) as _isNotFilled}<span class="level" />{/each}
      </td>
    </tr>
  {/each}
</table>
