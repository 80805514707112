<script lang="ts">
  import Education from "../components/education.svelte";

  enum ExperienceEnum {
    Full = "Yes",
    Partial = "Yes/No",
    None = "No",
  }

  const experience: {
    timePeriod: string;
    position: string;
    company: string;
    location: string;
    remote: ExperienceEnum;
  }[] = [
    {
      timePeriod: "2019 -",
      position: "Tech Lead",
      company: "Skiwo",
      location: "Norway",
      remote: ExperienceEnum.Full,
    },
    {
      timePeriod: "2019 - 2019",
      position: "Software Architect",
      company: "Scoville",
      location: "Japan",
      remote: ExperienceEnum.Partial,
    },
    {
      timePeriod: "2017 - 2019",
      position: "App & Systems Architect",
      company: "Saga Oppsyn",
      location: "Norway",
      remote: ExperienceEnum.Full,
    },
    {
      timePeriod: "2016 - 2017",
      position: "QA Engineer",
      company: "Hansoft / Favro",
      location: "Sweden",
      remote: ExperienceEnum.Partial,
    },
    {
      timePeriod: "2013 - 2016",
      position: "Designer / Developer",
      company: "Various",
      location: "Various",
      remote: ExperienceEnum.Partial,
    },
    {
      timePeriod: "2012 - 2013",
      position: "Protection Service Agent",
      company: "PayPal",
      location: "UK",
      remote: ExperienceEnum.None,
    },
    {
      timePeriod: "2010 - 2011",
      position: "Exchange Student",
      company: "JIU",
      location: "Japan",
      remote: ExperienceEnum.None,
    },
    {
      timePeriod: "2009 - 2012",
      position: "Student",
      company: "UiO",
      location: "Norway",
      remote: ExperienceEnum.None,
    },
  ];
</script>

<style>
  section {
    height: 100%;
    width: calc(50vw - 64px);
    overflow: hidden auto;
    padding: 0 32px;

    background-color: hsl(0, 0%, 30%);
    color: white;
  }
  @media screen and (max-width: 1350px) {
    section {
      width: calc(100% - 64px);
      padding-bottom: 32px;
    }
  }

  h2 {
    margin: 0;
    padding: 16px;
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    color: hsl(0, 0%, 90%, 0.7);
  }

  table {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 1px 1px 15px 0 hsla(0, 0%, 0%, 0.2);
  }
  tr {
    display: flex;
    border-bottom: 1px solid hsl(0, 0%, 20%, 1);
  }
  tr:last-child {
    border: none;
  }
  tr:nth-child(2n) {
    background-color: hsla(0, 0%, 20%, 0.25);
  }
  td,
  th {
    flex: 1;
    padding: 0 16px;
    margin: 8px 0;
  }
  td:nth-child(1n + 2) {
    border-left: 1px solid hsl(0, 0%, 20%, 0.5);
  }
  th {
    font-weight: 300;
    text-align: left;
  }
  td {
    font-weight: 100;
    text-align: left;
  }
  .timePeriod {
    min-width: 90px;
  }
  .title {
    min-width: 200px;
  }
  .company {
    min-width: 120px;
  }
  .location {
    min-width: 80px;
  }
  .remote {
    min-width: 80px;
    text-align: center;
    padding: 0;
  }
  @media screen and (min-width: 1350px) and (max-width: 1500px) {
    .remote {
      display: none;
    }
  }
  @media screen and (max-width: 770px) {
    .remote {
      display: none;
    }
  }
  @media screen and (max-width: 660px) {
    .location {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    tr:first-child {
      display: none;
    }
    tr {
      flex-wrap: wrap;
    }
    td:nth-child(1n + 2) {
      border-left: none;
    }
  }
</style>

<svelte:options tag="x-professional" />

<section>
  <h2>Career outline:</h2>

  <table>
    <tr>
      <th class="timePeriod">When</th>

      <th class="title">What</th>

      <th class="company">Who</th>

      <th class="location">Where</th>

      <th class="remote">Remote</th>
    </tr>

    {#each experience as xp}
      <tr>
        <td class="timePeriod">{xp.timePeriod}</td>

        <td class="title">{xp.position}</td>

        <td class="company">{xp.company}</td>

        <td class="location">{xp.location}</td>

        <td class="remote">{xp.remote}</td>
      </tr>
    {/each}
  </table>

  <x-education />
</section>
