<script lang="ts">
  import stack from "../stack";
  interface Stack {
    title: string;
    paths: string[];
  }

  const filteredStack = stack.filter(
    ({ started }) => typeof started !== "undefined"
  );

  const currentYear = new Date().getFullYear();
  const groupedStack: {
    [key: string]: Stack[];
  } = filteredStack.reduce(
    (acc, { started, ...stack }) => {
      switch (currentYear - started) {
        case 1:
        case 2:
        case 3:
          return { ...acc, one: [...acc.one, stack] };

        case 4:
        case 5:
        case 6:
          return { ...acc, four: [...acc.four, stack] };

        default:
          return { ...acc, seven: [...acc.seven, stack] };
      }
    },
    {
      seven: [],
      four: [],
      one: [],
    }
  );

  const getStackTitle = (key: string): string => {
    switch (key) {
      case "seven":
        return "7+ years experience";
      case "four":
        return "4+ years experience";
      case "one":
        return "1+ years experience";
    }
  };
</script>

<style>
  section {
    height: 100%;
    width: 25vw;
    overflow: hidden auto;

    background-image: linear-gradient(
        hsla(0, 0%, 80%, 0.7),
        hsla(0, 0%, 80%, 0.7)
      ),
      url("assets/images/bricks.jpg");
    background-repeat: repeat;
  }
  @media screen and (max-width: 1350px) {
    section {
      width: 100%;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  h5 {
    margin: 0;
    padding: 8px;
    background-color: hsla(0, 0%, 100%, 0.4);
    box-shadow: 1px 1px 5px 0 hsla(0, 0%, 0%, 0.3);
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }

  .skills {
    --skillSize: 80px;
  }
  @media screen and (min-width: 1350px) and (max-width: 1440px) {
    .skills {
      --skillSize: 76px;
      gap: 4px 4px !important;
    }
  }
  .skills {
    margin: 0 0 8px 0;
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--skillSize));
    gap: 8px 8px;
    justify-content: center;
  }
  .skill-container {
    width: var(--skillSize);
  }
  .skill {
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 5px 0 hsla(0, 0%, 0%, 0.3);
    border-radius: 4px;
    overflow: hidden;
  }
  .skill svg {
    height: calc(var(--skillSize) * 0.8);
    width: calc(var(--skillSize) * 0.8);
    padding: calc(var(--skillSize) * 0.1);
    background-color: hsla(0, 0%, 100%, 0.4);
  }
  .skill svg path {
    fill: var(--text-secondary);
    fill-rule: evenodd;
  }
  .skill .skill-title {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    cursor: default;

    text-align: center;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    word-break: break-word;

    width: var(--skillSize);
    background-color: hsla(0, 0%, 0%, 0.8);
    color: hsla(0, 0%, 100%, 1);
  }
</style>

<svelte:options tag="x-technical" />

<section>
  <ul>
    <li>
      {#each Object.keys(groupedStack) as stackKey}
        {#if groupedStack[stackKey].length}
          <h5>{getStackTitle(stackKey)}</h5>

          <ul class="skills">
            {#each groupedStack[stackKey] as { paths, title }}
              <li class="skill-container">
                <div class="skill">
                  <svg viewBox="0 0 128 128">
                    <g>
                      {#each paths as path}
                        <path d={path} />
                      {/each}
                    </g>
                  </svg>

                  <span class="skill-title">{title}</span>
                </div>
              </li>
            {/each}
          </ul>
        {/if}
      {/each}
    </li>
  </ul>
</section>
