<script lang="ts">
  const subjects = [
    { title: "Linguistics", ects: "10" },
    { title: "Philosophy", ects: "10" },
    { title: "Japanese", ects: "80" },
    { title: "English", ects: "50" },
    { title: "Psychology", ects: "60" },
    { title: "", ects: "210" },
  ];
</script>

<style>
  h4 {
    color: hsl(0, 0%, 90%, 0.7);
    padding: 0 16px;
    margin: 32px 0 16px;
    max-width: calc(520px - 32px);
    text-align: justify;
    font-weight: 300;
  }
  h4 > a {
    color: hsl(0, 0%, 90%, 0.7);
    text-decoration: none;
    border-bottom: 1px solid hsl(0, 0%, 90%, 0.7);
  }

  table {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 520px;
    box-shadow: 1px 1px 15px 0 hsla(0, 0%, 0%, 0.2);
  }
  tr {
    display: flex;
    border-bottom: 1px solid hsl(0, 0%, 20%, 1);
  }
  tr:nth-child(2n) {
    background-color: hsla(0, 0%, 20%, 0.25);
  }
  tr:last-child {
    border: none;
  }
  tr:last-child > td {
    border: none;
    font-weight: 300;
  }
  td,
  th {
    flex: 1;
    padding: 0 16px;
    margin: 8px 0;
  }
  td:nth-child(1n + 2) {
    border-left: 1px solid hsl(0, 0%, 20%, 0.5);
  }
  th {
    font-weight: 300;
    text-align: left;
  }
  td {
    font-weight: 100;
    text-align: left;
  }
</style>

<svelte:options tag="x-education" />

<h4>
  Higher education grouped by credits following the
  <a
    href="https://ec.europa.eu/education/resources/european-credit-transfer-accumulation-system_en"
    target="_blank"
    rel="noopener noreferrer">
    European Credit Transfer and Accumulation System (ECTS)
  </a>:
</h4>

<table>
  <tr>
    <th>Subject</th>
    <th>ECTS</th>
  </tr>

  {#each subjects as { title, ects }}
    <tr>
      <td>{title}</td>
      <td>{ects}</td>
    </tr>
  {/each}
</table>
